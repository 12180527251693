<template>
  <div class="transaction">
    <div class="d-flex flex-wrap justify-space-between mb-6">
      <div class="text-h4 font-weight-bold">{{ $t('transactions.title') }}</div>
      <!-- <div class="d-flex mt-2 mt-sm-0">
        <v-select
          class="select-item"
          v-model="select"
          :items="itemsSeect"
          item-text="name"
          item-value="value"
          append-icon="mdi-chevron-down"
          hide-details
          outlined
          required
          menu-props="auto"
          color="primary"
        >
        </v-select>
        <v-select
          class="select-item ml-5"
          v-model="select2"
          :items="itemsSeect2"
          item-text="name"
          item-value="value"
          append-icon="mdi-chevron-down"
          hide-details
          outlined
          required
          menu-props="auto"
          color="primary"
        >
        </v-select>
      </div> -->
    </div>
    <v-card rounded="lg" flat class="pt-3">
      <v-data-table class="d-none d-sm-block" :headers="headers" :items="data.results" hide-default-footer :mobile-breakpoint="300">
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.asset" height="88" class="black--text">
              <td>
                <div class="d-flex align-center">
                  <v-img class="me-10" max-width="20" height="20" :src="getArrow(item.type)"></v-img>
                  <div class="f18 font-weight-bold text-capitalize">{{ item.type.split('_').join(' ') }}</div>
                </div>
              </td>
              <td class="text-body-1">{{ new Date(item.datetime).toString().substr(0, 15) }}</td>
              <td class="text-body-1 font-weight-bold">SAR {{ (item.amount / 100).toFixed(2) }}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <div class="d-sm-none black--text">
        <div class="d-flex justify-space-between text-body-2 mobile-item">
          <div>{{ $t('transactions.table.col1') }}</div>
          <div>{{ $t('transactions.table.col3') }}</div>
        </div>
        <div v-for="item in data.results" :key="item.asset" class="d-flex justify-space-between mobile-item">
          <div>
            <div class="d-flex align-center mb-3">
              <v-img class="me-1" max-width="30" height="30" src="@/assets/icon/table-arrow.svg"></v-img>
              <div class="font-weight-bold text-capitalize">{{ item.type.split('_').join(' ') }}</div>
            </div>
            <div class="text-body-2">{{ new Date(item.datetime).toString().substr(0, 15) }}</div>
          </div>
          <div>
            <div class="font-weight-bold">SAR {{ (item.amount / 100).toFixed(2) }}</div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      select: { name: 'Bills', value: 'bills' },
      itemsSeect: [
        { name: 'Bills', value: 'bills' },
        { name: 'Test', value: 'test' },
        { name: 'Test', value: 'test' },
      ],
      select2: { name: 'All time', value: 'all' },
      itemsSeect2: [
        { name: 'All time', value: 'all' },
        { name: 'One week', value: 'week' },
        { name: 'One month', value: 'month' },
      ],
      headers: [
        { text: this.$t('transactions.table.col1'), sortable: false, value: 'type' },
        { text: this.$t('transactions.table.col2'), sortable: false, value: 'datetime' },
        { text: this.$t('transactions.table.col3'), sortable: false, value: 'amount' },
      ],
    };
  },
  methods: {
    getArrow(icon) {
      return require(`@/assets/icon/table-arrow-${icon == 'top_up' ? 'top' : 'bottom'}.svg`);
    },
  },
  mounted() {
    this.$store.dispatch('getTransactions');
  },
  computed: {
    data() {
      return this.$store.getters.transactionsList;
    },
  },
  destroyed() {
    this.$store.dispatch('setTransactions', {});
  },
};
</script>

<style lang="scss">
#app .transaction {
  .select-item {
    width: 150px;
    .v-input__slot {
      min-height: 45px;
      .v-select__selections {
        padding: 4px 0;
        .v-select__selection--comma {
          margin-top: 10px;
        }
      }
    }
    .v-input__append-inner {
      margin-top: 12px;
    }
  }
  th:first-child {
    padding-left: 85px !important;
  }
  .mobile-item {
    padding: 14px 12px;
  }
  .mobile-item:not(:last-child) {
    border-bottom: 1px solid #ebeff4;
  }
  .mobile-item:not(:first-child):hover {
    background: var(--v-accent-base);
  }
}
</style>
